import React from "react"
import { graphql } from "gatsby"
import Nav from "../components/nav"
import Footer from "../components/footer"
import { Link } from "gatsby"
import ArrowRight from "../components/icons/arrowRight"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const StoriesDynamicHarmony = ({ data,location }) => {
  const params = new URLSearchParams(location.search);
  var url_param = location.search;
  var url_param2;

  var utm_campaign = params.get("utm_campaign");
  var utm_source = params.get("utm_source");
  var utm_medium = params.get("utm_medium");
  var utm_content = params.get("utm_content");

  if(url_param){
    url_param = url_param.replace(/[^\w\s&=?]/gi, '').replace(/\s/g, '');
    url_param2 = url_param.replace(/\?/g, '&');
  }

  if(url_param){
    url_param = url_param.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^\w\s&=?]/gi, '').replace(/\s/g, '');
    url_param2 = url_param.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/\?/g, '&');
  }

  if(utm_campaign) {
    utm_campaign = utm_campaign.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_campaign = ""
  }
  if(utm_source) {
    utm_source = utm_source.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_source = ""
  }
  if(utm_medium) {
    utm_medium = utm_medium.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_medium = ""
  }
  if(utm_content) {
    utm_content = utm_content.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_content = ""
  }

  var url_param3 = "?utm_campaign="+utm_campaign+"&utm_source="+utm_source+"&utm_medium="+utm_medium+"&utm_content="+utm_content
  
  return (
    <Layout>
      <Nav urlParam={url_param}/>
      <div className="masthead relative">
        <div className="masthead hidden lg:block">
          <GatsbyImage
            image={getImage(
              data.allMdx.edges[0].node.frontmatter.masthead_desktop
            )}
            alt={data.allMdx.edges[0].node.frontmatter.title}
          />
        </div>
        <div className="masthead block lg:hidden">
          <GatsbyImage
            image={getImage(
              data.allMdx.edges[0].node.frontmatter.masthead_mobile
            )}
            alt={data.allMdx.edges[0].node.frontmatter.title}
          />
        </div>
        {/* <div className="relative md:absolute text-black md:text-white top-0 left-0 w-full h-full">
          <div className="container py-5 md:mt-36">
            <h1 className="font-light text-2xl md:text-4xl xl:text-5xl">
              {data.allMdx.edges[0].node.frontmatter.masthead_line1 !== ""}
              <span className="inline md:block">
                {data.allMdx.edges[0].node.frontmatter.masthead_line1}
                {data.allMdx.edges[0].node.frontmatter.masthead_line2 !==
                  "" && <span className="inline">&nbsp;</span>}
              </span>
              {data.allMdx.edges[0].node.frontmatter.masthead_line2 !== "" && (
                <span className="inline md:block md:mt-1">
                  {data.allMdx.edges[0].node.frontmatter.masthead_line2}
                </span>
              )}
            </h1>
          </div>
        </div> */}
      </div>
      <section className="section sectionFirst">
        <div className="container py-5">
          <div className="about-description">
            
          <h2 className="text-xl md:text-3xl xl:text-4xl font-light uppercase mb-10">Dynamic Harmony: The BMW i7 M70 – A two-toned luxury ride</h2>
            
            <p className="mb-5 lg:pr-5">
            The BMW i7 M70 stands out as a masterpiece, seamlessly blending cutting-edge technology with an exquisite aesthetic. This visionary masterpiece from BMW not only redefines the driving experience but also elevates the concept of luxury to new heights.
            </p>
            <h3 className="mb-3"><strong>Embracing Two-Tone Elegance</strong></h3>
            <p className="mb-5 lg:pr-5">
            Crafted with meticulous attention to detail, the BMW i7 M70 is a study in design elegance. Its aerodynamic contours and dynamic lines create a visual poetry that reflects both style and functionality.The exterior features an expressive and iconic front design featuring BMW’s ‘Iconic Glow’ crystal headlights with Swarvoski crystal elements, and an illuminated kidney contour radiate personality. M badges can be quickly located all around the body, a spoiler, M-specific side skirts as well as a diffuser - all in place to increase the car’s aggressiveness in line with its performance.
            </p>
            <h3 className="mb-3"><strong>Unmatched Driving Performance</strong></h3>
            <p className="mb-5 lg:pr-5">
            Beneath the elegantly sculpted exterior lies a powerhouse of innovation. The i7 M70 comes fitted with a 101.7kWh battery pack that produces 650bhp and 1,015Nm of torque which covers 490km of electric range.
            </p>
            <h3 className="mb-3"><strong>Interior Luxury Redefined</strong></h3>
            <p className="mb-5 lg:pr-5">
            Step inside, and the luxurious ambiance of the BMW i7 M70 unfolds. The spacious and meticulously designed interior showcases a perfect fusion of opulence and functionality. The intuitive infotainment system, seamlessly integrated into the dashboard, provides a futuristic interface that keeps you connected on the go. The state-of-the-art technology includes gesture controls, voice recognition, and a panoramic touchscreen display, offering a glimpse into the future of automotive interiors.
            </p>
            <h3 className="mb-3"><strong>Experience the BMW i7 M70 This Year</strong></h3>
            <p className="mb-5 lg:pr-5">
            As the new year approaches, head down to the BMW Eurokars Experience Centre and experience the BMW i7 M70. Sign up for a test drive and immerse yourself in the cityscape in this exquisite blend of style and performance. In the BMW i7 M70, luxury isn't just a feature, it's a lifestyle. Rediscover the pleasure of driving with BMW's latest masterpiece, a perfect way to start the new year.
            </p>
            
            <p className="mb-5 lg:pr-5">
            Visit the BMW Eurokars Experience Centre at 11 Kung Chong Road, Singapore 159147, or BMW Eurokars Auto Showroom at 11 Leng Kee Road, Singapore 159091, to test drive your dream BMW today. 
            </p>

            <p className="text-neutral-400 hover:text-black flex float-right">
              <span className="w-5 -ml-1.5 ">
                <ArrowRight />
              </span>
              <span className="ml-1 font-bold text-black md:transition-colors md:duration-150 md:text-neutral-400 md:hover:text-black">
              <Link
                  to={"/stories/" + url_param}
                  data-gtm-category="BMW Press Release"
                  data-gtm-action="Clicked_Link"
                  data-gtm-label="View Stories">
                  <strong>Back to Stories</strong>
                </Link>
              </span>
            </p>
              
            <br /><br />
            
            <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
            
          </div>

        </div>
      </section>
      <Footer urlParam={url_param} />
    </Layout>
  )
}
export const query = graphql`
  query {
    allMdx(filter: { frontmatter: { page_id: { eq: "stories-dynamic-harmony" } } }) {
      edges {
        node {
          frontmatter {
            slug
            page_id
            date
            title
            description
            masthead_line1
            masthead_line2
            masthead_desktop {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            masthead_mobile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 150 }
                  placeholder: BLURRED
                  breakpoints: [650]
                  outputPixelDensities: [2]
                )
              }
            }
          }
        }
      }
    }
  }
`

export default StoriesDynamicHarmony

const seoDetails = {
  title: "Eurokars Auto | BMW Eurokars Auto Stories - Dynamic Harmony",
  description:
    "As an official dealer of BMW, Eurokars Auto offers the full range of BMW vehicles – from fully electric BMW i models to high-performance BMW M models.",
  keywords: "Eurokars, Eurokars Auto, BMW, BMW Eurokars Auto",
  image: "og-eka-about-us.jpg",
}

export const Head = () => (
  <>
    <title>{seoDetails.title}</title>
    <meta property="og:type" content="website" />
    <meta
      name="description"
      property="description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="og:description"
      property="og:description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="keywords"
      property="keywords"
      content={seoDetails.keywords}
    ></meta>
    <meta
      name="og:url"
      property="og:url"
      content={`${process.env.GATSBY_BASE_URL}`}
    ></meta>
    <meta
      name="og:image"
      property="og:image"
      content={`${process.env.GATSBY_BASE_URL}/img/${seoDetails.image}`}
    ></meta>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=AW-782099393"
    ></script>

  </>
)
